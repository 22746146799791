import React from "react";
import Container from "../../../components/container";
import RadioButtons from "../../../components/RadioButtons";
import { MediaVideo } from "../../../components/MediaVideo";
import { useRef, useState } from "react";
import { navigate } from "gatsby";

const R = ({ children }) => (
  <span className="text-red-500 font-bold">{children}</span>
);

export default function Suma() {
  const responseRef = useRef();
  const [error, setError] = useState(false);

  const question = {
    intro: "Oh, las matemáticas",
    description: "Descripción pregunta",
    imageSrc: "/parrilla.webp",
    title: (
      <div>
        <p>
          Las mates son duras. Por eso os hacemos usarlas. El 98% falla en esta
          operación.
        </p>{" "}
        <p className="my-2">¿Cuál es el resultado?</p>
        <p className="text-center text-3xl">
          1 <R>+</R> 1 <R>+</R> 1 <R>+</R> 1 <R>+</R> 1 <R>×</R> 0 <R>+</R> 1
        </p>
      </div>
    ),
    options: [
      {
        id: 1,
        title: "El resultado es 1",
        // description: "Opción 1 descripción",
        response: {
          title: "Opción 1 respuesta - título",
          media: () => <MediaVideo src="/suso-no.mp4" />,
          description: "Opción 1 respuesta - descripción",
        },
        submit: (op) => {
          setError(true);
          console.log("-->", op);
        },
      },
      {
        id: 2,
        title: "El resultado es 5",
        // description: "Opción 2 descripción",
        response: {
          title: "Opción 2 respuesta - título",
          media: () => <MediaVideo src="/suso-yes.mp4" />,
          description: "Opción 2 respuesta - descripción",
        },
        submit: (op) => {
          setError(false);
          console.log("correcto", op);
          navigate("/taza1/acertijo/bate");
        },
      },
      {
        id: 3,
        title: "El resultado es 6",
        // description: "Opción 2 descripción",
        response: {
          title: "Opción 2 respuesta - título",
          media: () => <MediaVideo src="/suso-yes.mp4" />,
          description: "Opción 2 respuesta - descripción",
        },
        submit: (op) => {
          setError(true);
          console.log(op);
        },
      },
    ],
  };

  return (
    <Container>
      <section className="flex-col flex items-center md:justify-between mt-8 mb-16 md:mb-12">
        <h1 className="text-6xl md:text-8xl font-bold tracking-tighter leading-tight md:pr-8 text-center">
          Oh, las mates...
        </h1>
        <img
          className="mt-5 rounded-2xl "
          src="/mates.jpg"
          title="Matemáticas"
          alt="Matemáticas"
        />
        <div ref={responseRef}></div>
        <RadioButtons
          question={question}
          error={error}
          setError={setError}
          onSubmit={(data) => {
            // console.log("dataa", data);
            data.submit(data);
            responseRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }}
        />
      </section>
    </Container>
  );
}
